module.exports = {
    name: 'Equifax Engage',
    shortName: 'Engage',
    company: 'Equifax',
    logo: 'https://go.equifaxengage.com/media/equifax-logo.png',
    apiUrl: 'https://api.equifaxengage.com/2019-01-23/',
    portalUrl: 'https://go.equifaxengage.com/',
    chat: {
        name: 'Chat Console',
    },
};
